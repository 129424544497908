<template>
  <section class="q-pa-md">
    <h1>FSE-FBO5</h1>
    <p></p>
    <p class="text-h6">
      <template v-if="$_.get(data, 'id')">
        <q-btn
          :to="`/${$route.params.locale}/dashboard`"
          label="Dashboard"
          type="a"
          color="primary"
        />
      </template>
      <template v-else>
        <q-btn
          :to="`/${$route.params.locale}/sign-in`"
          label="Sign in"
          type="a"
          color="primary"
        />
        <span>
          to get started, or have a look at public
        </span>
        <q-btn
          :to="`/${$route.params.locale}/group`"
          label="Groups"
          type="a"
          color="secondary"
        />
      </template>
    </p>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const moduleName = 'auth'
  const { mapActions, mapState } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    computed: {
      ...mapState(
        [
          'data',
        ],
      ),
    },
  }
</script>
